'use strict';

angular.module('informaApp')
    .directive('infAdminAskAnalyst', ["AskAnalystSvc", "ConstantsSvc", "$routeParams", "$location", function (AskAnalystSvc, ConstantsSvc, $routeParams, $location) {
        return {
            restrict: 'E',
            templateUrl: 'directives/inf-admin-ask-analyst/template.ptl.html',

            scope: {},

            reloadData: function (scope) {
                var this_ = this;

                return AskAnalystSvc.getAllRequests().then(function (data) {
                    scope.requests = this_.sortData(data);
                });
            },

            loadSpecificRequest: function (id, scope) {
                var this_ = this;

                AskAnalystSvc.getRequestById(id).then(function (request) {
                    if (request){
                        scope.viewItemMode = true;
                        scope.activeItem = request;
                    } else {
                        this_.changeUrl();
                    }
                });
            },

            sortData: function (data) {
                return _.orderBy(data, ["isClosed", "createdAt"], ['asc', 'desc']);
            },

            changeUrl: function (id) {
                $location.path( "/admin/ask-analyst" + (id != null ? "/" + id : ""));
            },

            link: function (scope, element, attrs) {
                var this_ = this;

                scope.viewItemMode = false;
                scope.canChangeStatus = true;
                scope.dateFormat = ConstantsSvc.DateTime.fullAngularFormat;

                scope.showRequest = function (request) {
                    scope.viewItemMode = true;
                    scope.activeItem = request;
                };

                scope.email = function () {
                    window.location.href = "mailto:" + scope.activeItem.email + "?subject=" + scope.activeItem.subject;
                };

                scope.changeStatus = function () {
                    if (!scope.canChangeStatus){
                        return;
                    }

                    var newValue = !scope.activeItem.isClosed;

                    scope.canChangeStatus = false;

                    AskAnalystSvc.changeStatus(scope.activeItem.id, newValue).then(function () {
                        scope.canChangeStatus = true;
                        scope.activeItem.isClosed = newValue;
                    });
                };

                scope.backToList = function () {
                    this_.reloadData(scope).then(function () {
                        scope.viewItemMode = false;
                        scope.activeItem = null;

                        this_.changeUrl();
                    });
                };

                if ($routeParams.id != null){
                    if (Number.isInteger(+$routeParams.id)){
                        this.loadSpecificRequest($routeParams.id, scope);
                    } else {
                        this.changeUrl();
                    }
                } else {
                    this.reloadData(scope);
                }
            }
        };
    }]);